import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ActionTree } from "vuex";
import { State } from "./state";
import { archive } from "@/store/Crud/functions/archived/actions";
import { SET_DATA_LIST } from "@/store/Messaging/types";

const setCustomStudentList = (store: any, options: any): void => {
  store.commit(SET_DATA_LIST, options);
};

export default <ActionTree<State, any>>{
  findAll,
  findOne,
  update,
  create,
  filter,
  archive,
  setCustomStudentList
};
